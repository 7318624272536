var _excluded = ["title", "titleId"];
function _extends() {
  // eslint-disable-next-line no-func-assign
  _extends = Object.assign
    ? Object.assign.bind()
    : function (target) {
        for (var i = 1; i < arguments.length; i++) {
          var source = arguments[i];
          for (var key in source) {
            if (Object.prototype.hasOwnProperty.call(source, key)) {
              target[key] = source[key];
            }
          }
        }
        return target;
      };
  return _extends.apply(this, arguments);
}
function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};
  var target = _objectWithoutPropertiesLoose(source, excluded);
  var key, i;
  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }
  return target;
}
function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }
  return target;
}

import { jsx as ___EmotionJSX } from "@emotion/react";
var PmArrowForward = function PmArrowForward(_ref) {
  var title = _ref.title,
    titleId = _ref.titleId,
    props = _objectWithoutProperties(_ref, _excluded);
  return ___EmotionJSX(
    "svg",
    _extends(
      {
        xmlns: "http://www.w3.org/2000/svg",
        width: 16,
        height: 16,
        fill: "none",
        viewBox: "0 0 16 16",
        "aria-labelledby": titleId,
      },
      props
    ),
    title
      ? ___EmotionJSX(
          "title",
          {
            id: titleId,
          },
          title
        )
      : null,
    ___EmotionJSX("path", {
      fillRule: "evenodd",
      d: "M4.32666 13.4199L5.50666 14.5999L12.1067 7.9999L5.50666 1.3999L4.32666 2.5799L9.74666 7.9999L4.32666 13.4199V13.4199Z",
      clipRule: "evenodd",
    })
  );
};
export var icon = PmArrowForward;
