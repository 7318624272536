var _excluded = ["title", "titleId"];
function _extends() {
  // eslint-disable-next-line no-func-assign
  _extends = Object.assign
    ? Object.assign.bind()
    : function (target) {
        for (var i = 1; i < arguments.length; i++) {
          var source = arguments[i];
          for (var key in source) {
            if (Object.prototype.hasOwnProperty.call(source, key)) {
              target[key] = source[key];
            }
          }
        }
        return target;
      };
  return _extends.apply(this, arguments);
}
function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};
  var target = _objectWithoutPropertiesLoose(source, excluded);
  var key, i;
  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }
  return target;
}
function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }
  return target;
}

import { jsx as ___EmotionJSX } from "@emotion/react";
var PmCloseIcon = function PmCloseIcon(_ref) {
  var title = _ref.title,
    titleId = _ref.titleId,
    props = _objectWithoutProperties(_ref, _excluded);
  return ___EmotionJSX(
    "svg",
    _extends(
      {
        xmlns: "http://www.w3.org/2000/svg",
        width: 20,
        height: 20,
        viewBox: "0 0 20 20",
        "aria-labelledby": titleId,
      },
      props
    ),
    title
      ? ___EmotionJSX(
          "title",
          {
            id: titleId,
          },
          title
        )
      : null,
    ___EmotionJSX("path", {
      d: "M15.8334 5.34163L14.6584 4.16663L10 8.82496L5.34169 4.16663L4.16669 5.34163L8.82502 9.99996L4.16669 14.6583L5.34169 15.8333L10 11.175L14.6584 15.8333L15.8334 14.6583L11.175 9.99996L15.8334 5.34163Z",
      fill: "#1175CC",
    })
  );
};
export var icon = PmCloseIcon;
